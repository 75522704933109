<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-13
 * @Description: 营销管理/平台营销工具/平台卡券活动
-->
<template>
  <scroll-layout>
    <template #header>
      <ykc-search ref="YkcSearch" :data="searchItems" @click="handleSearchConfirm" />
    </template>
    <div>
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :title="tableTitle"
        operateFixedType="right"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <!--新建活动-->
            <el-dropdown @command="handleCommand">
              <ykc-button type="plain" v-rbac="'marketing:platform:card:addActivity'">
                新建活动
              </ykc-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :command="type.id"
                  v-for="(type, index) in activityTypes"
                  :key="index">
                  {{ type.name }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <ykc-button type="plain" v-rbac="'marketing:platform:card:export'" @click="exportBlob">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange" />
      </ykc-table>
      <!-- drawer start -->
      <ykc-drawer
        :show.sync="showDrawer"
        :title="drawerTitle()"
        :on-close="onAddDrawerClose"
        :before-cancel="onAddDrawerCancel">
        <template #footer>
          <div class="drawer-button-wrap">
            <template v-for="(btn, i) in drawerButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
                {{ btn.label }}
              </ykc-button>
            </template>
          </div>
        </template>
        <div class="drawer-main-body" v-if="showDrawer">
          <AddOrEdit
            :type="currentActivityType"
            ref="addOrEdit"
            :editModel="editModel"
            :id="currentActivityId" />
        </div>
      </ykc-drawer>
      <!-- drawer end -->
    </div>
  </scroll-layout>
</template>

<script>
  import { mapGetters } from 'vuex';
  import AddOrEdit from './AddOrEdit.vue';
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { code, offlineExport } from '@/utils'; // 新增/编辑组件
  import { couponActivity } from '@/service/apis';
  import { dicts, dictDesc } from '@/store/dict';

  const { activityTypes } = dicts;

  export default {
    name: 'platformCouponActivity',
    components: {
      AddOrEdit,
    },
    computed: {
      ...mapGetters(['getDic']),
    },
    data() {
      return {
        ruleId: '',
        showDrawer: false, // 是否展示抽屉
        editModel: 'add', // 编辑模式: add edit copy
        drawerType: 'edit',
        currentActivityType: '', // 当前卡券活动类型
        currentActivityId: '', // 当前idis
        activityTypes,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            disabled: () => false,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.stepsIndex = 0;
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return true;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.currentPage = 1;
                  this.getData();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
        configSet: {
          label: 'vehicleOrgName',
          value: 'vehicleOrgId',
        },
        pageButtons: [
          {
            id: '1',
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.exportBlob();
            },
          },
          {
            id: '2',
            text: '新建活动',
            type: 'plain',
            handleClick: () => {
              this.showDrawer = true;
              this.editModel = 'add';
              this.currentActivityId = '';
              this.currentActivityType = '';
            },
          },
        ],
        /**
         * 查询数据
         */
        searchItems: [
          {
            comName: 'YkcInput',
            key: 'activityName',
            label: '活动名称',
            placeholder: '请输入活动名称',
          },
          {
            comName: 'YkcDropdown',
            key: 'activityStatus',
            label: '活动状态',
            placeholder: '请选择活动状态',
            data: this.dicStatus(),
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'activityBeginTime',
            label: '开始时间',
          },
          {
            comName: 'YkcDatePicker',
            type: 'daterange',
            format: 'yyyy-MM-dd',
            key: 'activityEndTime',
            label: '结束时间',
          },
          {
            comName: 'YkcDropdown',
            key: 'activityType',
            label: '活动类型',
            placeholder: '请选择活动类型',
            data: activityTypes,
          },
        ],
        tableData: [],
        tableColumns: [
          { label: '活动编码', prop: 'activityId', minWidth: '180px', fixed: true },
          { label: '活动名称', prop: 'activityName', minWidth: '250px' },
          {
            label: '活动类型',
            minWidth: '100px',
            prop: 'activityType',
            scopedSlots: {
              default: ({ row }) => {
                return dictDesc('activityTypes', row.activityType);
              },
            },
          },
          { label: '开始时间', prop: 'activityBeginTime', minWidth: '150px' },
          { label: '结束时间', prop: 'activityEndTime', minWidth: '150px' },
          {
            label: '活动状态',
            prop: 'status',
            minWidth: '180px',
            scopedSlots: {
              default: ({ row }) => {
                const idcStatus = this.dicStatus();
                if (!row.activityStatus) {
                  return '——';
                }
                const status = idcStatus.filter(item => item.id === row.activityStatus)[0] || {};
                return status.name || '——';
              },
            },
          },
          { label: '修改人', prop: 'modifiedAccount', minWidth: '150px' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '180px' },
        ],
        tableTitle: '活动列表',
        // 列表项操作按钮
        tableOperateButtons: [
          {
            enabled: () => code('marketing:platform:card:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                class="do-enable-btn"
                disabled={row.activityStatus !== '2'}
                onClick={() => {
                  this.currentActivityId = row.activityId;
                  this.currentActivityType = row.activityType;
                  this.editModel = 'edit';
                  this.showDrawer = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:platform:card:stop'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  disabled={
                    row?.activityStatus !== '1' &&
                    row?.activityStatus !== '2' &&
                    row?.activityStatus !== '5'
                  }
                  onClick={() => {
                    this.stopActivity(row);
                  }}>
                  停用
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('marketing:platform:card:duplicate'),
            id: '2',
            text: '复制',
            handleClick: (btn, { row }) => {
              this.copyActivity(row);
            },
          },
          {
            enabled: () => code('marketing:platform:card:record'),
            id: '3',
            text: '记录',
            handleClick: (btn, { row }) => {
              this.exploreRecord(row);
            },
          },
          {
            enabled: () => code('marketing:platform:card:detail'),
            id: '4',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.go2Detail(row);
            },
          },
        ],
        total: 0,
        currentPage: 1,
        pageSize: 10,
      };
    },
    /// 生命周期
    created() {
      this.getData();
    },
    methods: {
      /**
       * 商户id
       * @returns {string}
       */
      getOperatorId() {
        return '30';
      },
      dicStatus() {
        // 活动状态（1:进行中，2:未开始，3:已结束(手动停用)，4:已结束(活动到期)，5:已结束(卡券不足)，6:已结束(卡券失效)）
        return [
          { id: '1', name: '进行中' },
          { id: '2', name: '未开始' },
          { id: '3', name: '已结束(手动停用)' },
          { id: '4', name: '已结束(活动到期)' },
          { id: '5', name: '已结束(卡券不足)' },
          { id: '6', name: '已结束(卡券失效)' },
        ];
      },
      /**
       * 点击查询
       * @param form
       */
      handleSearchConfirm(form) {
        this.currentPage = 1;
        const search = JSON.parse(JSON.stringify(form));
        if (search.activityBeginTime && search.activityBeginTime.length === 2) {
          [search.activityBeginTimeStart, search.activityBeginTimeEnd] = search.activityBeginTime;
        }
        if (search.endTime && search.endTime.length === 2) {
          [search.activityEndTimeStart, search.activityEndTimeEnd] = search.activityEndTime;
        }
        delete search.activityBeginTime;
        delete search.activityEndTime;
        this.searchParams = search || {};
        this.getData();
      },
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
        console.log('列表按钮点击事件', button, event);
      },
      /**
       * 导出
       */
      exportBlob() {
        offlineExport({
          downloadType: 'marketing_export',
          jsonNode: {
            downloadKey: 'cardCouponActivityList',
            activityInitiator: '1',
            ...this.searchParams,
          },
        });
      },
      handleCommand(activityType) {
        this.currentActivityType = activityType;
        this.currentActivityId = '';
        this.editModel = 'add';
        this.showDrawer = true;
      },

      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getData();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getData();
      },
      /**
       * 抽屉标题RR
       * @returns {string}
       */
      drawerTitle() {
        return `${this.editModel === 'edit' ? '编辑' : '新增'}${dictDesc(
          'activityTypes',
          this.currentActivityType
        )}活动`;
      },
      /**
       *
       * */
      onAddDrawerClose(done) {
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: dialogDone => {
            dialogDone();
          },
          onConfirm: dialogDone => {
            dialogDone();
            drawerDone();
            this.editModel = 'add';
          },
        });
      },
      getDictionary() {
        return [
          { id: 1, name: '进行中' },
          { id: 2, name: '未开始' },
          { id: 3, name: '已停止' },
          { id: 4, name: '已到期' },
        ]; // 活动状态（1进行中 2未开始 3已停止 4已到期）
      },
      /**
       * 取消之前
       * @param drawerDone
       */
      beforeCancel(drawerDone) {
        this.$dialog({
          dialogType: 'feedback',
          showTitle: false,
          closeOnClickModal: false,
          desc: '取消后操作将不被保存，确认是否取消？',
          onConfirm: done => {
            // 关闭弹框
            done();
            // 关闭抽屉
            drawerDone();
          },
        });
      },
      /**
       * @desc 获取远程数据
       * */
      getData() {
        couponActivity
          .list({
            ...(this.searchParams || {}),
            activityInitiator: '1',
            current: this.currentPage,
            size: this.pageSize,
          })
          .then(res => {
            this.tableData = res.records || [];
            this.total = res.total;
          });
      },
      /**
       * 停止活动
       * @param item
       */
      stopActivity(item) {
        this.$dialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认停用当前活动吗?',
          onConfirm: done => {
            couponActivity.stop({ activityId: item.activityId }).then(res => {
              console.log(res);
              done();
              this.$message({
                message: '停用成功',
                type: 'success',
              });
              this.currentPage = 1;
              this.getData();
            });
          },
        });
      },
      /**
       * 复制活动
       * @param item
       */
      copyActivity(item) {
        this.$dialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: '确认复制当前活动吗?',
          onConfirm: done => {
            this.editModel = 'copy';
            this.currentActivityId = item.activityId;
            this.currentActivityType = item.activityType;
            this.showDrawer = true;
            done();
          },
        });
      },
      /**
       * 查看记录
       * @param item
       */
      exploreRecord(item) {
        this.$router.push({
          path: '/marketingCenter/platformMarketingTools/marketingActivity/pickUpRecord',
          query: { activityId: item.activityId, activityType: item.activityType },
        });
      },
      /**
       * 打开详情
       * @param item
       */
      go2Detail(item) {
        this.$router.push({
          path: '/marketingCenter/details',
          query: { activityId: item.activityId, activityType: item.activityType },
        });
      },
    },
  };
</script>
<style lang="scss" scoped></style>
