<!--
 * @Author: wanghui@ykccn.com
 * @Date: 2023-02-11
 * @Description: 营销管理/平台营销工具/平台价格活动/新增或者编辑
 * 对应多种类型的卡券活动
-->
<template>
  <register-add-or-edit
    v-if="type === '1'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <recharge-add-or-edit
    v-else-if="type === '2'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <push-add-or-edit
    v-else-if="type === '3'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <charge-add-or-edit
    v-else-if="type === '4'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <scan-add-or-edit
    v-else-if="type === '5'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <exchange-add-or-edit
    v-else-if="type === '6'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
  <invite-add-or-edit
    v-else-if="type === '7'"
    :activityId="id"
    :editModel="editModel"
    ref="addOrEdit" />
</template>
<script>
  import RegisterAddOrEdit from './addOrEdits/RegisterAddOrEdit';
  import RechargeAddOrEdit from './addOrEdits/RechargeAddOrEdit';
  import PushAddOrEdit from './addOrEdits/PushAddOrEdit';
  import ChargeAddOrEdit from './addOrEdits/ChargeAddOrEdit';
  import ScanAddOrEdit from './addOrEdits/ScanAddOrEdit';
  import ExchangeAddOrEdit from './addOrEdits/ExchangeAddOrEdit';
  import InviteAddOrEdit from './addOrEdits/InviteAddOrEdit';

  export default {
    name: 'PlatFormPriceActivityEdit',
    components: {
      RegisterAddOrEdit,
      RechargeAddOrEdit,
      PushAddOrEdit,
      ChargeAddOrEdit,
      ScanAddOrEdit,
      ExchangeAddOrEdit,
      InviteAddOrEdit,
    },
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      id: {
        type: String,
      },
      type: {
        type: String,
        default: '', // 活动类型(1:注册领取，2:充值领取，3:定向发放，4:充电领取，5:扫码领取，6:券码兑换)
      },
    },
    data() {
      return {};
    },
    created() {},
    computed: {},
    methods: {
      submitForm() {
        return this.$refs.addOrEdit.submitForm();
      },
    },
  };
</script>
