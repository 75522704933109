<!--
 * @Description: 营销管理/平台营销工具/平台卡券活动/拉新活动
-->
<template>
  <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
    <ykc-form-item label="活动名称" prop="activityName">
      <ykc-input
        v-model="ruleForm.activityName"
        placeholder="请输入活动名称"
        maxlength="50"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="活动时间" prop="activityTime">
      <ykc-date-picker limitDate="limitBefore" v-model="ruleForm.activityTime"></ykc-date-picker>
    </ykc-form-item>
    <ykc-form-item label="活动说明" prop="activityRemark">
      <ykc-input
        v-model="ruleForm.activityRemark"
        type="textarea"
        placeholder="请输入活动说明"
        maxlength="500"></ykc-input>
    </ykc-form-item>
    <ykc-form-item label="邀请人卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData"
        :maxlength="20"
        addText="添加优惠券/抵用卡"
        ref="combination"></ykc-combination-item>
    </ykc-form-item>
    <ykc-form-item label="受邀请人卡券设置" class="required">
      <ykc-combination-item
        required
        :basicData="basicData"
        :selectData="coupons"
        :data="setData1"
        :maxlength="20"
        addText="添加优惠券/抵用卡"
        ref="combination1"></ykc-combination-item>
    </ykc-form-item>
  </ykc-form>
</template>
<script>
  import { couponActivity, marketManage } from '@/service/apis';
  import regexpObj from '@/utils/regexp';

  export default {
    props: {
      editModel: {
        type: String,
        default: 'add', // add edit copy
      },
      activityId: {
        type: String,
      },
    },
    data() {
      return {
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        detail: {},
        coupons: [],
        setData: [],
        setData1: [],
        selectData: [],
        basicData: [
          {
            comName: 'YkcDropdown',
            key: 'couponId',
            remote: true,
            value: '',
            placeholder: '请选择优惠券/抵用卡',
            rules: { required: true, message: '请选择优惠券/抵用卡', trigger: 'blur' },
            prepend: '卡券',
            only: true,
            data: [],
          },
          {
            comName: 'YkcInput',
            value: '',
            key: 'issueCount',
            maxlength: 8,
            prepend: '数量',
            append: '张/人',
            placeholder: '请输入卡券数量',
            rules: [
              { required: true, message: '请输入卡券数量', trigger: 'blur' },
              {
                pattern: regexpObj.regexp.input.integerNumberType.regexp,
                message: regexpObj.regexp.input.integerNumberType.errorTips.error(
                  '卡券数量',
                  99999999
                ),
                trigger: 'blur',
              },
              { validator: this.checkCouponNumber, trigger: 'blur' },
            ],
          },
        ],
        ruleForm: {
          activityName: '',
          activityTime: [],
          activityRemark: '',
        },
        rules: {
          activityName: [
            { required: true, message: '请输入活动名称', trigger: 'blur' },
            {
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('活动名称'),
              trigger: 'blur',
            },
            { validator: this.onlyCheckName, trigger: 'blur' },
          ],
          activityTime: [{ required: true, trigger: 'change', message: '请选择活动时间' }],
          activityRemark: [
            { required: false, trigger: 'blur', message: '请输入活动说明' },
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              message: regexpObj.regexp.input.textAreaType.errorTips.error('活动说明'),
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {
      // this.getItem = localGetItem('dictionary');
      this.getData();
    },
    methods: {
      getData() {
        const requests = [];
        const requestCoupons = marketManage.activityList({
          // cardCouponType://卡券类型（1:抵用卡，2:优惠券）
          useType: '1', // 使用类型（1:普通，2:会员）
          undertakerType: '1', // 承担方类型（1:平台，2:商户）
          // undertakerId://承担商户id
          // batchNumber;//批次号
        });

        requests.push(requestCoupons);

        if (this.activityId && (this.editModel === 'edit' || this.editModel === 'copy')) {
          const requestDetail = couponActivity.InvitingActivityDetail({
            activityId: this.activityId,
          });
          requests.push(requestDetail);
        }
        Promise.all(requests).then(([resCoupon, resDetail]) => {
          if (resDetail) {
            this.detail = resDetail || {};
          }
          if (resCoupon) {
            this.coupons = [];
            // if (
            //   this.activityId &&
            //   (this.detail.activityStatus === '1' || this.detail.activityStatus === '2')
            // ) {
            //   // 只有未开始进行中，进行复制卡券
            //   this.detail.rewardList.forEach(item => {
            //     this.coupons.push({
            //       cardCouponId: item.rewardId,
            //       id: item.rewardId,
            //       name: item.rewardName,
            //       cardCouponType: item.rewardType,
            //       leftCount: item.rewardCount,
            //     });
            //   });
            // }
            const coupons = resCoupon.map(item => {
              const { cardCouponId, cardCouponName, remainderNum } = item;
              item.id = cardCouponId;
              item.name = cardCouponName;
              item.leftCount = remainderNum; // 此处需注意 remainderNum 字段 后端接口没有返回
              return item;
            });
            this.coupons.push(...coupons);
            // 如果是选中状态
            if (this.activityId) {
              this.$nextTick(() => {
                this.setData = this.formatSetData('rewardList'); // 邀请人
                this.setData1 = this.formatSetData('inviteeRewardList'); // 受邀请人
              });
            }
          }
          if (this.activityId) {
            this.fillInitData();
          }
        });
      },
      fillInitData() {
        if (this.editModel === 'edit') {
          this.ruleForm.activityName = this.detail.activityName;
          this.ruleForm.activityTime = [];
          if (this.detail.activityBeginTime) {
            this.ruleForm.activityTime.push(this.detail.activityBeginTime);
          }
          if (this.detail.activityEndTime) {
            this.ruleForm.activityTime.push(this.detail.activityEndTime);
          }
        }
        this.ruleForm.activityRemark = this.detail.activityRemark || '';
      },
      formatSetData(key) {
        const selectedCoupons = [];
        this.detail[key]?.forEach(item => {
          selectedCoupons.push({
            couponId: item.rewardId,
            issueCount: item.rewardCount,
          });
        });
        return selectedCoupons;
      },
      /**
       * 选择树形控件
       * @param tree
       * @param selectedNodes
       */
      selectTreeNodes(tree, selectedNodes) {
        this.$nextTick(() => {
          tree.setCheckedKeys(selectedNodes, true);
        });
      },

      submitForm() {
        return new Promise(resolve => {
          const couponValidForm = this.$refs.combination.getResult();
          const couponValidForm1 = this.$refs.combination1.getResult();
          const dataValidForm = this.validateAllForm();
          Promise.all([couponValidForm, couponValidForm1, dataValidForm]).then(
            ([couponResult, couponResult1]) => {
              // 新增/复制/编辑
              if (couponResult.validState) {
                const submitData = this.formData();
                submitData.rewardList = this.formatCouponData(couponResult.data || []); // 邀请人
                submitData.inviteeRewardList = this.formatCouponData(couponResult1.data || []); // 受邀请人
                console.log(submitData, '保存参数++++');
                couponActivity.InvitingActivitySubmit(submitData).then(res => {
                  console.log(res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                });
              }
            }
          );
        });
      },
      /**
       * 校验表单
       * @returns {Promise<unknown>}
       */
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(err => {
              console.log(err);
              reject();
            });
        });
      },
      formatCouponData(selectedCoupons) {
        const couponDatas = [];
        if (selectedCoupons.length > 0) {
          selectedCoupons.forEach(selectItem => {
            const selectId = selectItem.couponId;
            for (let i = 0; i < this.coupons.length; i++) {
              const originCoupon = this.coupons[i];
              if (originCoupon.cardCouponId === selectId) {
                couponDatas.push({
                  rewardType: originCoupon.cardCouponType,
                  rewardId: selectId,
                  rewardCount: selectItem.issueCount || '',
                });
                break;
              }
            }
          });
        }
        return couponDatas;
      },
      formData() {
        const startTime =
          this.ruleForm.activityTime[0].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[0]
            : `${this.ruleForm.activityTime[0]} 00:00:00`;
        const endTime =
          this.ruleForm.activityTime[1].indexOf('00:00:00') > -1
            ? this.ruleForm.activityTime[1]
            : `${this.ruleForm.activityTime[1]} 00:00:00`;
        const formData = {
          activityType: '7',
          activityInitiator: '1',
          activityInitiatorId: '0',
          activityName: this.ruleForm.activityName,
          activityBeginTime: startTime,
          activityEndTime: endTime,
          activityRemark: this.ruleForm.activityRemark || '',
        };
        // 编辑/copy
        if (this.editModel === 'edit') {
          formData.activityId = this.activityId;
        }
        return formData;
      },
      /**
       * 输入框数量校验
       */
      checkCouponNumber(rule, value, callback) {
        // 输入的所有数据
        const result = this.$refs.combination.dealResult();
        // 当前行index
        const rowIndex = rule.field.split('.')[1];
        // 选择的优惠券id
        const { couponId } = result[rowIndex] || { couponId: '' };

        if (!couponId) {
          return callback();
        }
        const [coupon] = this.coupons.filter(item => item.id === couponId);

        return Number(value) > coupon.leftCount
          ? callback(`超过剩余数量${coupon.leftCount}张，请重新输入`)
          : callback();
      },
      /**
       * 活动名称校验
       */
      onlyCheckName(rule, value, callback) {
        if (value) {
          couponActivity
            .checkNameOnly({
              activityName: value,
              activityType: '7',
              activityId: this.editModel === 'edit' ? this.activityId : '',
            })
            .then(res => {
              if (res?.repeatFlag) {
                // 重复
                // 校验未通过
                this.nameValidError = '活动名称已存在'; // res.resultDesc;
                callback(new Error(this.nameValidError));
                // eslint-disable-next-line no-dupe-else-if
              } else if (res?.repeatFlag === false) {
                // 校验通过
                this.nameValidError = '';
                callback();
              }
            })
            .catch(() => {});
        }
      },
    },
  };
</script>
<style lang="scss"></style>
